@media print{
  @page{
    margin:0.5cm;
  }

  h2,h3,p{
    orphans:3;
  }

  h2,h3{
    page-break-after:avoid;
  }

  body,html{
    background-color:#fff;
  }

  .giftcard-header{
    padding:10px 0;
  }

  .giftcard__border,.giftcard__content{
    border:0 none;
  }

  .add-to-apple-wallet,.giftcard__actions,.giftcard__wrap:after,.giftcard__wrap:before,.site-header__logo-link img:nth-child(2),.tooltip{
    display:none;
  }

  .giftcard__title{
    float:none;
    text-align:center;
  }

  .giftcard__code__text{
    color:#555;
  }

  .template-giftcard .shop-url{
    display:block;
  }

  .template-giftcard .logo{
    color:#58686f;
  }
}
